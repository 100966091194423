import { Literal, Runtype, Union } from "runtypes";

const LEARN_BASE_URL = `https://learn.hex.tech` as const;
export const MARKETING_SITE_BASE_URL = `https://hex.tech` as const;
const DOCS_BASE_URL = `${LEARN_BASE_URL}/docs` as const;
const QUICKSTART_BASE_URL = `${LEARN_BASE_URL}/tutorials/quickstart` as const;
const TUTORIAL_BASE_URL = `${LEARN_BASE_URL}/tutorials` as const;
const MAGIC_BASE_URL = `${LEARN_BASE_URL}/docs/hex-magic` as const;
const TRUST_BASE_URL = "https://trust.hex.tech" as const;

// TODO: rename to LEARN_LINKS
export const DOCS_LINKS = {
  // top level
  Root: LEARN_BASE_URL,
  Changelog: `${LEARN_BASE_URL}/changelog` as const,
  Trust: TRUST_BASE_URL,
  // Core Docs
  Docs: DOCS_BASE_URL,
  AllowedDomains:
    `${DOCS_BASE_URL}/administration/overview#allowed-domains` as const,
  Api: `${DOCS_BASE_URL}/explore-data/notebook-view/hex-api/hex-api-overview` as const,
  ApiPersonalAccessTokens:
    `${DOCS_BASE_URL}/explore-data/notebook-view/hex-api/hex-api-overview#personal-access-tokens` as const,
  ApiWorkspaceTokens:
    `${DOCS_BASE_URL}/explore-data/notebook-view/hex-api/hex-api-overview#workspace-tokens` as const,
  AppLinks:
    `${DOCS_BASE_URL}/share-insights/apps/publish-and-share-apps#distribute-a-link-to-your-app` as const,
  AppView: `${DOCS_BASE_URL}/share-insights/apps/app-builder` as const,
  AppDisplayOptions:
    `${DOCS_BASE_URL}/share-insights/apps/app-configuration-options#app-display-options` as const,
  AppRunSettings:
    `${DOCS_BASE_URL}/share-insights/apps/app-configuration-options#app-run-settings` as const,
  AppTabs: `${DOCS_BASE_URL}/share-insights/apps/app-builder#app-tabs` as const,
  Archive: `${DOCS_BASE_URL}/organize-content/archive` as const,
  AskMagic: `${DOCS_BASE_URL}/hex-magic/ask-magic` as const,
  AuditLogs:
    `${DOCS_BASE_URL}/administration/workspace_settings/audit-logs/audit-logs-overview` as const,
  AutoRun:
    `${DOCS_BASE_URL}/share-insights/apps/app-configuration-options##automatically-rerun-downstream-cell-default` as const,
  BigQueryStorageAPI:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/data-connections-introduction#add-a-new-connection` as const,
  BuiltInVars:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/environment-views#built-in-variables` as const,
  CacheDefaultState:
    `${DOCS_BASE_URL}/build-apps/app-configuration-options` as const,
  Calculations: `${DOCS_BASE_URL}/explore-data/cells/calculations` as const,
  CellExecutionOrder:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/environment-views#cell-execution-order` as const,
  CellLinks:
    `${DOCS_BASE_URL}/explore-data/projects/compute-model/execution-model#how-cells-link-together` as const,
  Collections: `${DOCS_BASE_URL}/sharing/collections` as const,
  CollectionsPermissions:
    `${DOCS_BASE_URL}/organize-content/collections#permissions` as const,
  Components: `${DOCS_BASE_URL}/explore-data/components` as const,
  ComponentsPermissions:
    `${DOCS_BASE_URL}/explore-data/components#component-permissions` as const,
  ComputeProfile:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/environment-views#compute-profiles` as const,
  AdvancedComputeSettings: `${DOCS_BASE_URL}/administration/workspace_settings/compute`,
  AdvancedComputeSpendLimit: `${DOCS_BASE_URL}/administration/workspace_settings/compute#set-a-compute-spend-limit`,
  CustomLogo:
    `${DOCS_BASE_URL}/administration/workspace_settings/custom-app-logos` as const,
  DataConnections:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/data-connections-introduction` as const,
  DataConnectionsDatabaseSecurity:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/data-connections-introduction#database-security` as const,
  DataConnectionsSchemaFiltering:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/data-connections-introduction#connection-filtering` as const,
  DataConnectionsCanQueryPermissions:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/data-connections-introduction#can-query` as const,
  DataConnectionsCanViewResultsPermissions:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/data-connections-introduction#can-view-results` as const,
  DataframeSQL:
    `${DOCS_BASE_URL}/explore-data/cells/sql-cells/sql-cells-introduction#dataframe-sql` as const,
  DataManager: `${MAGIC_BASE_URL}/data-manager` as const,
  DefaultStatusWorkspaceSetting:
    `${DOCS_BASE_URL}/administration/workspace_settings/workspace-assets#default-status` as const,
  DirectorySync:
    `${DOCS_BASE_URL}/administration/workspace_settings/directory-sync` as const,
  StatusesAndCategories:
    `${DOCS_BASE_URL}/organize-content/statuses-categories` as const,
  Endorsements:
    `${DOCS_BASE_URL}/organize-content/statuses-categories#endorsed-statuses` as const,
  ExternalFileProjectConfig:
    `${DOCS_BASE_URL}/connect-to-data/cloud-storage-integrations#import-files-from-your-cloud-storage-integration` as const,
  ExternalFileWorkspaceConfig:
    `${DOCS_BASE_URL}/administration/workspace_settings/workspace-assets#external-file-integrations` as const,
  WritebackCell:
    `${DOCS_BASE_URL}/explore-data/cells/data-cells/writeback-cells` as const,
  DbtIntegration:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/dbt-integration` as const,
  Embedding: `${DOCS_BASE_URL}/share-insights/embedding` as const,
  EnvironmentViews:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/environment-views` as const,
  EnvironmentVariables:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/environment-views#environment-variables` as const,
  GitHubPackageImport:
    `${DOCS_BASE_URL}/administration/workspace_settings/workspace-assets#github-packages` as const,
  GitHubPackageUse:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/using-packages#github-packages` as const,
  GitHubSync: `${DOCS_BASE_URL}/explore-data/projects/git-sync` as const,
  GitExport: `${DOCS_BASE_URL}/explore-data/projects/git-export` as const,
  GitSyncReviews:
    `${DOCS_BASE_URL}/explore-data/projects/git-sync#git-sync-and-reviews` as const,
  IPAddresses:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/allow-connections-from-hex-ip-addresses` as const,
  InferNumericDatatypes:
    `${DOCS_BASE_URL}/explore-data/cells/sql-cells/sql-cells-introduction#infer-numeric-datatypes` as const,
  Jinja: `${DOCS_BASE_URL}/explore-data/cells/using-jinja` as const,
  KernelLimits:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/project-kernels#manually-killing-active-kernels` as const,
  KernelIdleTimeout:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/project-kernels#kernel-timeouts` as const,
  LegacyAppView: `${DOCS_BASE_URL}/share-insights/apps/app-builder` as const,
  LegacyChartCell:
    `${DOCS_BASE_URL}/explore-data/cells/visualization-cells/chart-cells` as const,
  Library:
    `${DOCS_BASE_URL}/organize-content/statuses-categories#library` as const,
  LogicView:
    `${DOCS_BASE_URL}/explore-data/notebook-view/develop-your-notebook` as const,
  MagicSchemaDescriptions:
    `${DOCS_BASE_URL}/hex-magic/magic-tips#magic-knows-about-table-and-column-metadata` as const,
  ManagingMemory:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/manage-memory` as const,
  NotionShareFAQ:
    `${DOCS_BASE_URL}/share-insights/embedding#how-do-permissions-work-for-notion-link-previews` as const,
  ParameterizeSQL:
    `${DOCS_BASE_URL}/explore-data/cells/using-jinja#sql-parameterization` as const,
  Permissions:
    `${DOCS_BASE_URL}/collaborate/sharing-and-permissions/sharing-permissions` as const,
  PivotCells:
    `${DOCS_BASE_URL}/explore-data/cells/transform-cells/pivot-cells` as const,
  PreRun:
    `${DOCS_BASE_URL}/share-insights/apps/app-configuration-options#pre-run-cells-in-background` as const,
  ProjectFilters:
    `${DOCS_BASE_URL}/share-insights/apps/project-filters` as const,
  Projects: `${DOCS_BASE_URL}/getting-started/intro-to-projects` as const,
  Privacy: `${DOCS_BASE_URL}/security/privacy-policy` as const,
  Publishing:
    `${DOCS_BASE_URL}/share-insights/apps/publish-and-share-apps` as const,
  PublicSharePermissions:
    `${DOCS_BASE_URL}/administration/workspace_settings/workspace-security#share-projects-to-web` as const,
  QueryCaching:
    `${DOCS_BASE_URL}/explore-data/cells/sql-cells/query-caching` as const,
  QueryMode:
    `${DOCS_BASE_URL}/explore-data/cells/sql-cells/sql-cells-introduction#query-mode` as const,
  Reviews: `${DOCS_BASE_URL}/collaborate/reviews` as const,
  RSupport: `${DOCS_BASE_URL}/previews/r-support` as const,
  RunModes:
    `${DOCS_BASE_URL}/explore-data/projects/compute-model/run-modes-and-cell-staleness` as const,
  Scheduling:
    `${DOCS_BASE_URL}/share-insights/scheduled-runs-and-notifications` as const,
  ScheduleNotifications:
    `${DOCS_BASE_URL}/share-insights/scheduled-runs-and-notifications#scheduled-run-notifications` as const,
  ScheduleNotificationsUpdatePublishedResults:
    `${DOCS_BASE_URL}/share-insights/scheduled-runs#update-published-results` as const,
  ScheduleNotificationsSqlCaching:
    `${DOCS_BASE_URL}/share-insights/scheduled-runs#scheduled-runs--sql-caching` as const,
  Secrets:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/environment-views#secrets` as const,
  SSHConnections:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/connect-via-ssh` as const,
  ShareWithWorkspace:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/data-connections-introduction#add-shared-connections` as const,
  SharedAssetRoleDowngradeToViewer:
    `${DOCS_BASE_URL}/collaborate/sharing-and-permissions/project-sharing#unable-to-edit-due-to-missing-workspace-asset-permissions` as const,
  SharedAssetRoleDowngradeToNoAccess:
    `${DOCS_BASE_URL}/collaborate/sharing-and-permissions/project-sharing#unable-to-view-a-project-due-to-missing-data-connection-permissions` as const,
  SnowflakeOAuth:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/snowflake-oauth` as const,
  SnowflakeOAuthExpiredAppCredentials:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/snowflake-oauth#expired-app-credentials` as const,
  SnowflakeOAuthTokenSharing:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/snowflake-oauth#snowflake-oauth-token-sharing` as const,
  SnowparkIntegration:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/snowpark` as const,
  Terms: `${DOCS_BASE_URL}/security/terms-and-conditions` as const,
  Timezones:
    `${DOCS_BASE_URL}/explore-data/cells/visualization-cells/chart-cells#chart-cell-timezones` as const,
  TimezonesWorkspaceSetting:
    `${DOCS_BASE_URL}/administration/workspace_settings/overview#workspace-timezone` as const,
  UpdatePublishedResults:
    `${DOCS_BASE_URL}/share-insights/apps/app-configuration-options#data-freshness` as const,
  UpgradingProjects:
    `${DOCS_BASE_URL}/tips-and-tricks/upgrading-projects-to-hex-2.0` as const,
  UploadingFiles:
    `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/files` as const,
  UserRoles:
    `${DOCS_BASE_URL}/collaborate/sharing-and-permissions/roles` as const,
  // TODO (dscott): docs link for "Use native Python dates"
  UserGroups:
    `${DOCS_BASE_URL}/administration/workspace_settings/overview#groups` as const,
  UseNativeDates:
    `${DOCS_BASE_URL}/explore-data/cells/sql-cells/sql-cells-introduction` as const,
  VersionControl:
    `${DOCS_BASE_URL}/explore-data/projects/history-and-versions` as const,
  VisualFiltering:
    `${DOCS_BASE_URL}/explore-data/cells/visualization-cells/chart-cells#configuring-a-chart-cell` as const,
  MigratingFromVisualFiltering:
    `${DOCS_BASE_URL}/explore-data/cells/visualization-cells/chart-cells#configuring-a-chart-cell` as const,
  TableDisplayFilters:
    `${DOCS_BASE_URL}/explore-data/cells/visualization-cells/table-display-cells#filters` as const,
  Transfers:
    `${DOCS_BASE_URL}/explore-data/projects/create-and-manage-projects#transfer-ownership` as const,
  // Quickstart
  QuickStart: QUICKSTART_BASE_URL,
  Hex101: `${QUICKSTART_BASE_URL}/hex-101` as const,
  Admin101: `${QUICKSTART_BASE_URL}/workspace-admin-101` as const,
  // Tutorials
  Tutorials: TUTORIAL_BASE_URL,
  Magic: MAGIC_BASE_URL,
  MagicTypeahead: `${MAGIC_BASE_URL}/magic-typeahead` as const,
  MagicTerms: `${DOCS_BASE_URL}/security/magic-tos` as const,
  ImproveMagic:
    `${TUTORIAL_BASE_URL}/connect-to-data/improving-hex-magic` as const,
  MagicTermsTypeahead:
    `${DOCS_BASE_URL}/legal/hex-magic-beta-terms-and-conditions` as const,
  SchemaRefreshSchedule:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/overview#schedule-automated-refreshes-for-the-data-browser` as const,
  SqlFormatting:
    `${DOCS_BASE_URL}/connect-to-data/data-connections/sql-formatting` as const,
  PublishAsSnapshot: `${DOCS_BASE_URL}/share-insights/apps/snapshots` as const,
  CustomKernels:
    `${DOCS_BASE_URL}/administration/workspace_settings/custom-images` as const,
  Security: `${MARKETING_SITE_BASE_URL}/security` as const,
  ExportDownloadFiles: `${DOCS_BASE_URL}/explore-data/projects/environment-configuration/files#download-files`,
  Explore: `${DOCS_BASE_URL}/share-insights/explore` as const,
  ViewData:
    `${DOCS_BASE_URL}/explore-data/cells/visualization-cells/chart-cells#view-data` as const,
  CsvDownload: `${DOCS_BASE_URL}/administration/workspace_settings/workspace-security#allow-viewers-to-download-and-copy-csvs-from-tables`,
  DebugMode: `${DOCS_BASE_URL}/share-insights/apps/debug-apps` as const,
  DebugModePrunedCells:
    `${DOCS_BASE_URL}/share-insights/apps/debug-apps#cell-run-behavior-in-apps` as const,
  PresentationMode:
    `${DOCS_BASE_URL}/share-insights/apps/publish-and-share-apps#presentation-mode` as const,
  S3Integration:
    `${DOCS_BASE_URL}/connect-to-data/cloud-storage-integrations#amazon-s3` as const,
  GoogleDriveIntegration:
    `${DOCS_BASE_URL}/connect-to-data/cloud-storage-integrations#google-drive` as const,
  WorkspaceAssets:
    `${DOCS_BASE_URL}/administration/workspace_settings/workspace-assets` as const,
  WorkspaceSecurityProjectDataConnections:
    `${DOCS_BASE_URL}/administration/workspace_settings/workspace-security#project-data-connections` as const,
} as const;

export type DocsLinkName = keyof typeof DOCS_LINKS;
export const DocsLinkName = Union(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...(Object.keys(DOCS_LINKS).map((k) => Literal(k)) as any),
) as Runtype<DocsLinkName>;

export type DocsLinkValue = (typeof DOCS_LINKS)[DocsLinkName];

export const MARKETING_SITE_LINKS = {
  RequestExplore: `${MARKETING_SITE_BASE_URL}/request-explore-demo`,
  UseCases: `${MARKETING_SITE_BASE_URL}/use-cases`,
};
